import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import get from 'lodash.get';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/hands-writing.jpg';
import CtaDeck from '../components/common/cta-deck';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { INVESTOR_VISA_IMMIGRATION_TESTIMONIALS } from '../data/investor-visa-usa-immigration/testimonials';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import CategorySlider from '../components/blog/category-slider';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'Investor Visa USA | Investment Immigration | Pandev Law',
  description:
    'Searching for a trusted and experienced Investor Immigration Lawyer? Whether it&#39;s an EB-5, E-2, or L-1 visa Pandev Law is here to help you. Talk to us now!',
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "Investor Visa USA | Investment Immigration | Pandev Law",
    "description": "Searching for a trusted and experienced Investor Immigration Lawyer? Whether it&#39;s an EB-5, E-2, or L-1 visa Pandev Law is here to help you. Talk to us now!",
    "url": "https://www.pandevlaw.com/investor-visa-usa-immigration",
    "image": "https://pandevlaw.com/img/hands-writing.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/investor-visa-usa-immigration/"
  },
  "headline": "Investor Visa USA",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function InvestorVisaUsaImmigration({ location, data }) {
  const category = get(data, 'category', null);

  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title="Investor Visa USA" img={heroImg}>
        <p className="text-lead mb-5 mb-md-6">
          I am an experienced investor visa USA attorney who helps foreign nationals
          obtain a US green card by investment or an investment-based visa. I combine my
          business law and investor immigration law experience to represent you throughout
          the entire investment immigration USA process, including initial investment
          planning, transaction structuring, contract drafting, and your investor visa
          application. I listen to your short and long-term goals to advise you on the
          best investor immigration solution for your specific needs. I design and execute
          your personalized entrepreneur visa USA strategy. My investor visa USA services,
          include:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="Investor Immigration Client Reviews"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/american-passport.png"
                alt="EB-5 Investor Visa"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">
                <a href="/eb-5-visa" className="">
                  EB-5 Investor Visa USA
                </a>
              </h2>
              <p className="text-body mb-6">
                The EB-5 investor visa is a great option for individuals with significant
                financial resources to obtain a green card through investment. The EB-5
                investor visa USA option may be the fastest way for you to obtain
                permanent residence in the US if you do not have a US employer or family
                member who can sponsor you for a US green card or visa. The EB-5 visa
                program is also a great entrepreneur visa USA option for entrepreneurs
                whose startup business requires substantial capital investment. I have the
                experience and know-how to help you successfully navigate the EB-5 visa
                USA program.
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseOne">
                      <strong>Requirements for an EB-5 Investor Visa</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      To qualify for an EB-5 green card by investment, you must invest at
                      least $900,000 or $1,800,000, depending on the geographic area, into
                      a US enterprise that will employ 10 full-time US workers. The EB-5
                      visa rules allow you to invest in specifically designated investment
                      projects, known as Regional Centers, which will indirectly create
                      employment for 10 US workers. As an EB-5 visa USA applicant, you do
                      not have to be involved in the day-to-day operation of the business,
                      but you are not allowed to be an entirely passive investor. The
                      funds you invest must be at risk. The EB-5 investor visa regulations
                      require that you be able to demonstrate the lawful source of your
                      invested funds.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseTwo">
                      <strong>Types of Qualifying EB-5 Investments</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingTwo"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      <strong>Regional Center Investment EB-5 Visa USA</strong>
                      <p className="mt-4">
                        The EB-5 investor visa rules allow you to invest in a government
                        pre-approved Regional Center that will either directly or
                        indirectly create the required 10 US jobs. Almost all Regional
                        Centers are located in geographic areas that qualify for the lower
                        $900,000 investment amount.
                      </p>
                      <p className="mt-4">
                        The Regional Center investment is a great US investor green card
                        option for EB-5 visa applicants who prefer a more hands-off
                        approach in the management of their investment. A qualified
                        Regional Center should provide the EB-5 investor visa applicant
                        with a detailed project plan, including an explanation of how the
                        Regional Center’s project will create the requisite 10 US jobs.
                      </p>
                      <p className="mt-4">
                        As your EB-5 visa USA attorney, I review the Regional Center’s
                        project plan and work directly with the Regional Center throughout
                        the processing of your case to ensure compliance with investor
                        immigration rules.
                      </p>
                    </div>
                    <div className="mt-4">
                      <strong>Direct Investment EB-5 Visa USA</strong>
                      <p className="mt-4">
                        To obtain a green card through investment, you may choose to
                        invest in your own commercial enterprise. If you are an
                        entrepreneur with a specific business idea, this may be the best
                        entrepreneur visa USA option for you. You may invest in any
                        enterprise to qualify for an EB-5 US green card by investment as
                        long as the commercial enterprise creates 10 jobs for US workers.
                      </p>
                      <p className="mt-4">
                        If you choose the Direct Investment EB-5 visa route, I have the
                        experience to effectively represent you throughout your entire
                        entrepreneur visa USA process, including company formation,
                        investment related business transactions, and your EB-5 US
                        investor green card application.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="corporateLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseThree">
                      <strong>EB-5 Investor Visa USA Process</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseThree"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingThree"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      Successful EB-5 US investor green card applicants initially receive
                      conditional permanent residence for 2 years. After 2 years, you must
                      petition USCIS to obtain a permanent green card. To qualify you will
                      have to demonstrate that your investment created the required 10 US
                      jobs and that the invested funds remained committed and at risk
                      throughout the entire EB-5 investor visa process. As your EB-5 visa
                      USA attorney, I have the experience to represent you throughout your
                      entire US green card by investment process, including your initial
                      2-year conditional green card application and the removal of
                      conditions on your US investor green card.
                    </div>
                    <div className="mt-4">
                      Your family members, including your spouse and children under 21,
                      can also take advantage of the EB-5 green card through investment
                      process. The EB-5 investor visa USA regulations allow spouses and
                      children under the age of 21 to petition together with the main
                      applicant and obtain a US investor green card.
                    </div>
                  </div>
                </div>
                <div>
                  <div id="corporateLawHeadingFour">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseFour"
                      aria-expanded="false"
                      aria-controls="corporateLawCollapseFour">
                      <strong>Why Choose an EB-5 Investor Visa?</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseFour"
                    className="collapse"
                    aria-labelledby="corporateLawHeadingFour"
                    data-parent="#corporateLaw">
                    <div className="mt-4">
                      <p>
                        Each investment immigration USA solution may not be the best
                        option for every candidate. The EB-5 investor visa may be a great
                        choice for your case in the following circumstances:
                      </p>
                    </div>
                    <div className="mt-4">
                      <ol className="corporateLawOrderedList">
                        <li className="noBold">
                          <strong>Professionals:</strong>
                          <p className="mt-4">
                            If you are a foreign professional working in a career
                            unrelated to your US investment and would like to further your
                            career in the United States, then the EB-5 Regional Center
                            investment solution may be a great option for you.
                          </p>
                          <p className="mt-4">
                            The EB-5 investor visa program allows you to continue to
                            advance your career abroad while you wait for the approval of
                            your US investor green card. Once you receive your green card
                            through investment, you can move to the United States and work
                            for any US employer. If you think moving to the United States
                            will allow you to advance your career, then you should
                            consider the EB-5 investment immigration USA solution.
                          </p>
                        </li>
                        <li className="mt-4">
                          <strong>School-aged children:</strong>
                          <p className="mt-4">
                            If you have school aged children who are considering attending
                            high school, college, or university in the United States, then
                            you should consider the EB-5 investor visa program. The EB-5
                            visa program allows children under the age of 21 to apply for
                            a US investor green card together with the main applicant
                            parent. The following are some of the advantages of the EB-5
                            investor visa USA program for school-aged children:
                          </p>
                          <ol className="mt-4">
                            <li className="noBold">
                              If your child obtains a US green card by investment through
                              you, he or she could attend a US college or university at US
                              resident tuition rates. This could amount to significant
                              savings in tuition fees when compared to international
                              student rates.
                            </li>
                            <li className="mt-4 noBold">
                              After receiving a US green card by investment, your child
                              would not have to apply for an F-1 international student
                              visa. F-1 student visas place restrictions on a foreign
                              student’s ability to seek employment in the United States
                              both during their course of study and after graduation.
                              Moreover, the F-1 visa application process at US consulates
                              and embassies can be stressful, time consuming, and, at
                              times, expensive.
                            </li>
                            <li className="mt-4 noBold">
                              Once your child has obtained a green card through
                              investment, your child can seek employment, including
                              internships, with US employers anywhere in the United
                              States.
                            </li>
                          </ol>
                        </li>
                        <li className="mt-4">
                          <strong>Starting US business:</strong>
                          <p className="mt-4">
                            If you are starting a business in the United States which
                            requires significant capital investment, the EB-5 investor
                            visa may be a great option for you. The EB-5 Direct Investment
                            solution is a great entrepreneur visa USA option because it
                            allows you and your family to reside in the United States
                            indefinitely while you are growing your US business.
                          </p>
                          <p className="mt-4">
                            If your initial investment was below the required amount for
                            an EB-5 visa, you may still qualify for a US green card by
                            investment at a later date. If you continue to invest in your
                            US business and your total investment over the years surpasses
                            the amount required by the EB-5 investor visa USA regulations,
                            then you may qualify for a US investor green card. In fact, if
                            you are currently in the United States on an E-2 or L-1
                            investor visa and you have invested above the required amount,
                            you may petition for an EB-5 green card through investment.
                            The EB-5 program is a great entrepreneur visa USA option for
                            those entrepreneurs who wish to live in the United States
                            indefinitely.
                          </p>
                        </li>
                        <li className="mt-4">
                          <strong>
                            Retirees and others seeking permanent residence in the United
                            States:
                          </strong>
                          <p className="mt-4">
                            If you wish to reside in the United States indefinitely
                            without having to renew or apply for a US visa, then the EB-5
                            visa USA program may be a good choice for you.
                          </p>
                          <p className="mt-4">
                            The EB-5 investor visa USA regulations do not require that the
                            investor actively manage the US enterprise in which he or she
                            has invested. In fact, once you receive your green card
                            through investment you may choose not to work at all.
                          </p>
                        </li>
                        <li className="mt-4">
                          <strong>Dreams of US citizenship:</strong>
                          <p className="mt-4">
                            For those who wish to become US citizens, there is no better
                            investment immigration USA solution than the EB-5 investor
                            visa. Because the EB-5 investor visa program leads to a US
                            investor green card, it grants the approved applicant
                            permanent residence in the United States. After 5 years of
                            being a permanent resident of the United States you can apply
                            to become a naturalized US citizen.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                to="/eb-5-visa/"
                className="btn btn-secondary d-block d-md-inline-block button-cta mt-5">
                More on EB-5 Visa
              </Link>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/woman-smiling.png"
                alt="E-2 Business Investment Visa USA"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                <a href="/e-2-visa" className="">
                  E-2 Business Investment Visa USA
                </a>
              </h2>
              <p className="text-body mb-6">
                The E-2 investor visa is a great investment immigration USA solution for
                individuals with some financial capital who wish to move to the United
                States. The E-2 business investment visa USA program is a great
                alternative to the EB-5 US investor green card because the E-2 investor
                visa regulations do not have a minimum investment amount. If you would
                like to move to the United States through investment but wish to invest
                less than the amounts required for an EB-5 visa, you may wish to consider
                the E-2 investor visa USA program. The E-2 visa is also a great
                entrepreneur visa USA solution for those with a business idea they would
                like to develop in the United States.
              </p>
              <div className="accordion" id="internationalBusinessLaw">
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseZero"
                      aria-expanded="true"
                      aria-controls="internationalBusinessLawCollapseZero">
                      <strong>E-2 Investor Visa USA Advantages</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseZero"
                    className="collapse show"
                    aria-labelledby="internationalBusinessLawHeadingZero"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body mb-4">
                      The E-2 investor visa is a nonimmigrant visa, meaning it does not
                      lead to permanent residence in the United States. If processed at a
                      US consulate or embassy, an E-2 investor visa can be valid for up to
                      5 years. After expiration of the visa, you will have to renew it to
                      continue to be able to work and reside in the United States. The
                      good news is that the E-2 investment-based visa USA regulations
                      allow an E-2 investor visa to be renewed indefinitely.
                    </p>
                    <p className="mb-4">
                      This means that as long as you and your business continue to comply
                      with the E-2 investment-based visa USA regulations, you may continue
                      to renew your E-2 investor visa and reside in the United States. If
                      at any point after obtaining an E-2 visa you move away from the
                      United States, the E-2 visa’s nonimmigrant nature will allow you
                      obtain a visitor visa without the additional hurdles imposed on
                      permanent residents, such as EB-5 US investor green card holders.
                      The flexibility of being able to renew your E-2 visa indefinitely
                      combined with the lack of additional hurdles if you decide to move
                      away from the United States, make the E-2 visa one of the best
                      entrepreneur visa USA options.
                    </p>
                    <p className="mb-4">
                      As your E-2 investment immigration USA attorney, I represent you
                      throughout the entire E-2 visa process, from the initial investment
                      transactions through your E-2 entrepreneur visa USA application.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseOne"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseOne">
                      <strong>E-2 Business Investment Visa USA Requirements</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseOne"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingOne"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body mb-4">
                      To qualify for an E-2 visa, you must meet all requirements of the
                      E-2 investor visa USA program. You can find a summary of the E-2
                      investor immigration requirements below:
                    </p>
                    <ol className="corporateLawOrderedList">
                      <li className="noBold mb-4">
                        <strong>Treaty country nationality:</strong>
                        <p className="mt-4">
                          The E-2 investor visa applicant must be a treaty country
                          national. Because the E-2 investor visa USA program is based on
                          the existence of a treaty of friendship, commerce, and
                          navigation between the United States and the applicant’s country
                          of citizenship, the E-2 investor visa is only available to you
                          if the US and your home country have the requisite treaty in
                          place.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Substantial investment:</strong>
                        <p className="mt-4">
                          The E-2 entrepreneur visa USA regulations require that your
                          investment be substantial in proportion to the cost of the
                          business you are starting in the United States. Unlike the EB-5
                          US investor green card program, the E-2 investor immigration
                          rules do not provide a preset minimum investment amount.
                        </p>
                        <p className="mt-4">
                          Whether US immigration authorities will consider your investment
                          substantial will depend on the likelihood that your investment
                          will allow you to successfully develop and direct your US
                          business. Generally, US immigration authorities consider
                          investments above $100,000 as substantial. This is a general
                          guideline and should not be interpreted as a hard floor. I have
                          successfully represented E-2 applicants who were approved with
                          investments below $50,000. In all of these cases, the business
                          had lower startup costs, as is the case often in professional
                          service industries.
                        </p>
                        <p className="mt-4">
                          The E-2 investment immigration USA regulations require that the
                          investment amount be proportional to the regular startup
                          expenses for the type of business you are starting. For example,
                          the investment amount for a manufacturing facility will be
                          significantly higher than a consulting business with minimal
                          startup expenses. Gifted funds and certain loans may be used in
                          an investment to qualify for an E-2 investor visa. The lack of a
                          fixed minimum investment amount make the E-2 visa an attractive
                          entrepreneur visa USA option.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Cannot be marginal:</strong>
                        <p className="mt-4">
                          The E-2 business investment visa USA regulations mandate that
                          your business may not be marginal. This means that your business
                          cannot solely provide a living for you and your family. The E-2
                          investor visa USA rules require that you demonstrate that your
                          business will grow sufficiently to require the hiring of US
                          employees in the near future.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Investment already made:</strong>
                        <p className="mt-4">
                          To qualify for the E-2 entrepreneur visa USA program, you must
                          actively be in the process of investing or have already invested
                          the funds to start your US business. The E-2 investment
                          immigration USA rules require that all funds you are investing
                          be irrevocably committed. Moreover, the invested funds must be
                          at risk of loss.
                        </p>
                        <p className="mt-4">
                          To be approved for an E-2 investor visa, you must also be close
                          to commencing business operations. Your business does not need
                          to be operating at the time of application. However, you need to
                          be ready to start operating once your E-2 investor visa is
                          approved.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Lawful source of funds:</strong>
                        <p className="mt-4">
                          To qualify for the E-2 investor visa USA program, you must be
                          able to show the lawful source of investment funds. In some
                          cases, due to local business practices in certain countries, it
                          may not be easy to trace the source of funds even when such
                          funds were lawfully earned. Through my extensive experience
                          working with investors from around the world, I have developed
                          innovative strategies relying on unconventional evidence to
                          document the source of funds in even the most difficult cases.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Willingness to develop and direct the enterprise:</strong>
                        <p className="mt-4">
                          The E-2 investment immigration USA regulations require that the
                          investor be coming to the United States to develop and direct
                          the US business. Although I have represented E-2 applicants with
                          no business experience, it is helpful to demonstrate how your
                          skills and experience will allow you to successfully direct and
                          develop your US business.
                        </p>
                        <p className="mt-4">
                          The E-2 investor visa USA program requires that the E-2 investor
                          applicant own at least 50% of the US business.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Intent to depart the United States:</strong>
                        <p className="mt-4">
                          The E-2 investor immigration rules require that you express an
                          intent to depart the United States after expiration of your E-2
                          investor visa. Although you cannot intend to remain in the
                          United States permanently, the rules allow you to extend your
                          E-2 visa indefinitely as long as you continue meet all
                          requirements.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="internationalBusinessLawHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseTwo"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseTwo">
                      <strong>E-2 Investor Visa USA Process</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseTwo"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingTwo"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      The E-2 entrepreneur visa USA process can be broken down into two
                      phases. The first phase encompasses all investment planning,
                      structuring, and business transactions related to investment. The
                      second phase involves documenting the investment and preparing the
                      visa application. As your investment immigration USA attorney, I use
                      my extensive business law and investor immigration experience to
                      advise you throughout the entire E-2 investor visa USA process.
                      Below I highlight the main steps for most E-2 business investment
                      visa USA cases:
                      <br />
                      <br />
                      <ul className="list-bullets">
                        <li>
                          Identify and evaluate your proposed investment for E-2 purposes.
                        </li>
                        <li>Develop and structure your investment strategy.</li>
                        <li>
                          Form your US company, including filing formation documents with
                          the Secretary of State, drafting relevant partnership or
                          operating agreements, and obtaining all relevant business
                          licenses, permits, and tax identification numbers.
                        </li>
                        <li>
                          Finalize all company formation related transactions, including
                          opening US bank accounts and obtaining US office space or other
                          business premises
                        </li>
                        <li>
                          Negotiate and finalize all investment transactions, including
                          drafting and reviewing relevant contracts.
                        </li>
                        <li>
                          Assemble, review, and organize all investment related documents
                          to demonstrate E-2 visa eligibility.
                        </li>
                        <li>
                          Prepare and file E-2 visa application with the appropriate U.S
                          consulate, embassy, or USCIS.
                        </li>
                      </ul>
                    </div>
                    <p className="mt-4">
                      Your family members, including your spouse and children under 21,
                      can also take advantage of the E-2 entrepreneur visa USA process.
                      The EB-2 investor immigration regulations allow spouses and children
                      under the age of 21 to apply together with the main applicant and
                      obtain an E-2 dependent visa. While your spouse may obtain work
                      authorization to work anywhere in the United States, your children
                      will not be able to work in the United States while on an E-2
                      dependent visa
                    </p>
                  </div>
                </div>
                <div>
                  <div id="internationalBusinessLawHeadingThree">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#internationalBusinessLawCollapseThree"
                      aria-expanded="false"
                      aria-controls="internationalBusinessLawCollapseThree">
                      <strong>Why Choose the E-2 Investor Visa USA Program?</strong>
                    </h2>
                  </div>
                  <div
                    id="internationalBusinessLawCollapseThree"
                    className="collapse"
                    aria-labelledby="internationalBusinessLawHeadingThree"
                    data-parent="#internationalBusinessLaw">
                    <div className="mt-4">
                      Each investment-based visa USA program is not be the best option in
                      every case. The E-2 entrepreneur visa USA option may be a great
                      solution for you in the following cases:
                      <br />
                      <br />
                      <ol className="corporateLawOrderedList">
                        <li className="noBold">
                          <strong>
                            Investment amount below EB-5 investor visa requirement:
                          </strong>
                          <p className="my-4">
                            If you would like to move to the United States through an
                            investor immigration program but do not want to invest the
                            significant capital required by the EB-5 US investor green
                            card program, the E-2 investor visa USA may be a great option
                            for you. Because the E-2 entrepreneur visa USA program does
                            not have a required minimum investment amount, you could move
                            to the United States even if your business does not have high
                            startup expenses.
                          </p>
                        </li>
                        <li className="noBold">
                          <strong>Starting US business:</strong>
                          <p className="my-4">
                            If you want to own a business in the United States or if you
                            want expand your foreign company in the United States, then
                            the E-2 business investment visa USA program may be the right
                            solution for you. The E-2 investor visa is a great
                            entrepreneur visa USA option because it allows your family
                            members to accompany you to the United States. While you are
                            growing your US company, your children can attend school and
                            your spouse can work for a US employer anywhere in the United
                            States.
                          </p>
                        </li>
                        <li className="noBold">
                          <strong>Flexible length of stay:</strong>
                          <p className="mt-4">
                            Because the E-2 investor visa is a nonimmigrant visa, it
                            provides tremendous flexibility regarding the length of your
                            stay in the United States. Often, foreign entrepreneurs know
                            they want to move to the United States to start and grow their
                            business. However, they are unsure whether they want to remain
                            in the United States after their business is fully
                            operational. The E-2 entrepreneur visa USA program is the
                            perfect solution for such a scenario.
                          </p>
                          <p className="my-4">
                            Because the E-2 investor visa can be extended indefinitely,
                            the investor can remain in the United States until necessary.
                            If the E-2 investor wishes to depart the United States at any
                            point, he or she can do so without any additional legal
                            hurdles faced by permanent residents. This flexibility of
                            being able to remain in the United States for an extended
                            period of time combined with the ability to depart whenever
                            necessary, make the E-2 investor visa USA program a top
                            entrepreneur visa USA option.
                          </p>
                        </li>
                        <li className="noBold">
                          <strong>Relocating foreign employees to US operation:</strong>
                          <p className="mt-4">
                            The E-2 investor visa USA program is a great solution for your
                            business if you have foreign employees you would like to
                            relocate to your US operation. Once your US company is
                            registered as an E-2 business with the US consulate or embassy
                            in your home country, you will be able to sponsor your foreign
                            employees, both managers and those with essential skills, for
                            an E-2 employee visa.
                          </p>
                          <p className="my-4">
                            Given the E-2 investor visa’s quick processing times and the
                            flexibility in the applicant’s length of stay, this is a great
                            solution for business owners who want to relocate staff with
                            proprietary knowledge, unique skills, or managerial experience
                            to their US business. This is one another reason why the E-2
                            investor visa is a great entrepreneur visa USA option.
                          </p>
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
              <Link
                to="/e-2-visa/"
                className="btn btn-secondary d-block d-md-inline-block button-cta mt-5">
                More on E-2 Visa
              </Link>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/people-talking-outside.png"
                alt="L-1 Investor Visa USA"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                <a href="/l-1-visa" className="">
                  L-1 Investor Visa USA
                </a>
              </h2>
              <p className="text-body mb-5">
                While the L-1 investor visa is generally used for intracompany transfers
                of employees, it is also a great investor visa USA option for investors
                from non-treaty countries who cannot qualify for an E-2 investor visa or
                whose investment is below the EB-5 US investor green card minimum
                investment amount. If you are the owner of a foreign company and wish to
                establish a US subsidiary or other affiliated office, the L-1A investor
                immigration program allows you to transfer a manager or executive of your
                foreign company, including yourself, to the United States to establish a
                new US office. This makes the L-1 business investment visa USA program a
                great entrepreneur visa USA option.
              </p>
              <div className="accordion" id="InvestorVisaUSA">
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseZero"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseZero">
                      <strong>L-1 Business Investment Visa USA Advantages</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseZero"
                    className="collapse show"
                    aria-labelledby="InvestorVisaUSAHeadingZero"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      The L-1 investor visa is a nonimmigrant visa, meaning it is issued
                      for a limited duration. If you are entering the United States on an
                      L-1A investor visa to establish a new office, you will be granted a
                      maximum initial stay of 1 year. The L-1 investor visa USA program
                      rules allow you to extend your stay by 2 years, and you may continue
                      to do so until you reach your maximum time of 7 years in L-1A
                      status. Unlike the E-2 investor visa, an L-1 investor visa is a dual
                      intent visa which makes it easier to apply for a US green card if
                      you decide to live in the United States indefinitely. Because the
                      L-1 investor visa USA program requires the applicant to maintain
                      business operations both in the United States and abroad, L-1A
                      managers or executives may qualify for an EB-1-C Multinational
                      Manager green card, a great alternative to the EB-5 US investor
                      green card.
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The dual intent nature of the L-1 investor visa provides you with
                      the flexibility to decide the length of your stay in the United
                      States. If you would like to reside in the United States
                      permanently, the L-1A investor visa USA program allows you to
                      petition for an EB-1-C Multinational Manager green card or an EB-5
                      US investor green card. However, if you decide to depart the United
                      States, the nonimmigrant and dual intent nature of the L-1A investor
                      visa will help you receive a visitor visa without the additional
                      requirements faced by permanent residents, such as EB-5 US investor
                      green card holders. The flexibility of being able to move to the
                      United States before committing to becoming a permanent resident,
                      make the L-1 investor visa one of the best entrepreneur visa USA
                      options.
                    </p>
                    <p className="text-body mt-4 mb-5">
                      The L-1 investment immigration USA program functions as a great
                      alternative to the E-2 investor visa for individuals from non-treaty
                      countries. Unlike the E-2 investor visa which is only available to
                      citizens of certain countries, the L-1 investment-based visa USA
                      program is available to nationals of all countries worldwide. If you
                      are a citizen of a non-treaty country and your planned total
                      investment is below that required by the EB-5 investor visa USA
                      rules, the L-1 investor visa may be the best entrepreneur visa USA
                      solution for you.
                    </p>
                    <p className="text-body mt-4 mb-5">
                      I rely on my cross-border transaction, foreign direct investment,
                      and international subsidiary formation experience to provide legal
                      advice and guidance throughout the entire L-1A investment
                      immigration USA process.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseOne"
                      aria-expanded="false"
                      aria-controls="InvestorVisaUSACollapseOne">
                      <strong>L-1 Investor Visa USA Requirements</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseOne"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingOne"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      To qualify for an L-1 investor visa, you must meet all requirements
                      of the new office L-1 investor visa USA program. Below you can find
                      a summary of the new office L-1 investor immigration requirements:
                    </p>
                    <ol className="corporateLawOrderedList">
                      <li className="noBold">
                        <strong>Qualifying relationship with foreign company:</strong>
                        <p className="my-4">
                          The L-1 investor visa USA rules mandate that there be a
                          qualifying relationship between the US company and the foreign
                          entity. To be eligible for the L-1 investor visa USA program,
                          you must show that your US company is a subsidiary, branch, or
                          other affiliate of your foreign company.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>
                          Doing business both in the United States and abroad:
                        </strong>
                        <p className="my-4">
                          The L-1 entrepreneur visa USA regulations require that your
                          company be doing business in both the United States and a
                          foreign country for at least the duration of your L-1 investor
                          visa stay. The rules define “doing business” as the “regular,
                          systematic, and continuous provision of goods and/or services.”
                          The presence of an agent or an office alone will not constitute
                          doing business and will not meet this L-1 investor immigration
                          requirement.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>
                          Employed as a manager or executive in US business:
                        </strong>
                        <p className="my-4">
                          The L-1 investment immigration USA program requires that you be
                          entering the United States to work in an executive or managerial
                          capacity. This is different from the EB-5 investor visa USA
                          program which allows you to obtain a US investor green card
                          without having to actively manage your US business. While an L-1
                          investor may hire managers to supervise the day-to-day
                          operations of the US business, the investor cannot take a
                          completely hands-off role without violating the L-1 entrepreneur
                          visa USA rules.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>
                          Employed as manager or executive in foreign company:
                        </strong>
                        <p className="my-4">
                          The L-1 business investment visa USA rules require that you have
                          worked in a managerial or executive capacity for the foreign
                          entity for 1 continuous year within the last 3 years immediately
                          preceding your admission to the United States in L-1A status.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>Physical premises:</strong>
                        <p className="my-4">
                          To be eligible for the L-1 investor visa USA program, your US
                          company must have secured sufficient physical premises for its
                          new office in the United States.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>Able to support executive or managerial position:</strong>
                        <p className="my-4">
                          The L-1 investor visa USA rules require that you demonstrate
                          that your new US operation will grow to a size sufficient to
                          support an executive or managerial position within one year of
                          approval of your L-1 investor visa petition. Because most new
                          ventures cannot show significant revenue and do not hire
                          numerous employees in their first year of operation, it is
                          critical to demonstrate the projected growth of your US company.
                          Through my years of experience working with foreign investors, I
                          have devised various strategies on how to document a newly
                          formed US enterprise’s need for a managerial or executive level
                          employee within one year of commencing operation.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseTwo"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseTwo">
                      <strong>L-1A Investor Visa Process</strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseTwo"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingTwo"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      The L-1 investor visa USA process can be broken down into three
                      phases. First, you must establish a new US affiliate office and make
                      all necessary investments to commence doing business in the United
                      States. Second, you document your investment and prepare your L-1
                      investor visa petition for filing with USCIS. Third, once your L-1
                      investor immigration petition is approved by USCIS, your case is
                      transferred to a US consulate or embassy where you can apply for
                      your L-1 investment immigration USA visa.
                    </p>
                    <p className="text-body mt-4 mb-5">
                      As your entrepreneur visa USA attorney, I use my extensive business
                      law and investor immigration experience to represent you throughout
                      the entire L-1 investor visa USA process. I have the necessary
                      foreign direct investment experience to help you plan and structure
                      your company’s US expansion and the investor immigration background
                      to prepare your L-1 investor immigration application. A summary of
                      the L-1 investment-based visa USA process is provided below:
                    </p>
                    <ol>
                      <li className="mt-4">
                        Evaluate your proposed plan for your new L-1 affiliate office in
                        the United States.
                      </li>
                      <li className="mt-4">
                        Develop a detailed strategy for your US expansion.
                      </li>
                      <li className="mt-4">
                        Organize your US affiliate office, including filing of
                        organizational documents with the Secretary of State, drafting
                        relevant affiliation agreements, and obtaining all necessary
                        permits, licenses, and tax numbers.
                      </li>
                      <li className="mt-4">
                        Finalize all new US office formation related transactions,
                        including opening necessary business bank accounts and obtaining
                        the required physical premises, such as US office space.
                      </li>
                      <li className="mt-4">
                        Negotiate and finalize all investment transactions, including
                        drafting and reviewing relevant contracts.
                      </li>
                      <li className="mt-4">
                        Assemble, review, and organize all new US affiliate office related
                        documents to demonstrate L-1 investor visa eligibility.
                      </li>
                      <li className="mt-4">
                        Prepare and file L-1 investor visa petition with USCIS.
                      </li>
                      <li className="mt-4">
                        Prepare and file L-1 investor visa application with the
                        appropriate U.S consulate or embassy, if the applicant is outside
                        the United States at the time the L-1 investor visa petition was
                        filed.
                      </li>
                    </ol>
                    <p className="text-body mt-4 mb-5">
                      Your family members, including your spouse and children under 21,
                      can also take advantage of the L-1 investor visa USA process. The
                      L-1 entrepreneur visa USA rules allow your spouse and children under
                      the age of 21 to apply for an L-1 dependent visa together with your
                      L-1 investor visa application. The L-1 investor immigration USA
                      regulations permit your spouse to work anywhere in the United
                      States. Your children may attend school on an L-1 dependent visa but
                      may not work in the United States.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="InvestorVisaUSAHeadingTwo">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#InvestorVisaUSACollapseTwo"
                      aria-expanded="true"
                      aria-controls="InvestorVisaUSACollapseTwo">
                      <strong>
                        Why Choose the L-1 Business Investment Visa USA Program?
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="InvestorVisaUSACollapseTwo"
                    className="collapse"
                    aria-labelledby="InvestorVisaUSAHeadingTwo"
                    data-parent="#InvestorVisaUSA">
                    <p className="text-body mt-4 mb-5">
                      A particular investor immigration program is not always the best fit
                      for everyone. To determine whether the L-1 investor visa USA program
                      is a good option for your case, please find a summary of key
                      considerations below:
                    </p>
                    <ol className="corporateLawOrderedList">
                      <li className="noBold">
                        <strong>
                          Investment amount below EB-5 US investor green card requirement:
                        </strong>
                        <p className="my-4">
                          If your new US business does not need a capital investment
                          greater than that required by the EB-5 investor visa program,
                          but you wish to relocate to the United States through
                          investment, then the L-1 investment immigration USA program may
                          be a good fit for you. The L-1 entrepreneur visa USA option does
                          not require a minimum investment, allowing you to move to the
                          United States regardless of the startup costs of your new US
                          business.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>Not a treaty country citizen:</strong>
                        <p className="my-4">
                          The L-1 investor visa USA program is a great option if you wish
                          to expand your foreign business to the United States but are not
                          from a treaty country. Unlike the E-2 investor visa, the L-1
                          investment immigration USA program is available to citizens of
                          all countries worldwide. The L-1 investor visa is a great
                          entrepreneur visa USA solution because it allows your spouse and
                          children under the age of 21 to accompany you to the United
                          States to develop your business.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>Considering becoming a permanent resident:</strong>
                        <p className="my-4">
                          Because the L-1 investor visa is a dual intent nonimmigrant
                          visa, it provides you with the most flexibility in deciding
                          whether to apply to become a permanent resident in the United
                          States. The L-1 investor visa USA program does not require you
                          to intend to depart the United States at the conclusion of your
                          stay in L-1 status. This means that if you would like to make
                          the United States your permanent home, you can file for a US
                          green card without the additional steps required for other
                          nonimmigrant visa holders. In addition to the EB-5 visa USA
                          program, L-1 investors often qualify for an EB-1-C Multinational
                          Manager green card.
                        </p>
                      </li>
                      <li className="noBold">
                        <strong>Transferring foreign workers to US operation:</strong>
                        <p className="my-4">
                          The L-1 investor visa USA option may be useful to your business
                          if you would like to transfer individuals employed by your
                          foreign affiliate entity to your US company. The L-1
                          entrepreneur visa USA rules permit a qualifying business to
                          transfer foreign employees working in a managerial, executive,
                          or specialized knowledge capacity to its US operations. The
                          spouse and children under the age of 21 of your qualifying
                          foreign employees would be eligible for L-1 dependent visas,
                          making the L-1 an appealing investor immigration option.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <Link
                to="/l-1-visa/"
                className="btn btn-secondary d-block d-md-inline-block button-cta mt-5">
                More on L-1 Visa
              </Link>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/dock-from-the-top.png"
                alt="E-1 International Trade Investor Visa USA"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                E-1 International Trade Investor Visa USA
              </h2>
              <p className="text-body mb-6">
                Although not traditionally considered an investor visa USA program, the
                E-1 treaty trader visa is a great alternative to the traditional US
                investor immigration programs, if your foreign business engages in
                substantial trade with the United States. Unlike the EB-5 US investor
                green card or the E-2 investor visa USA program, the E-1 treaty trader
                visa does not have any investment requirement. Instead, the E-1 visa is
                granted to applicants who can demonstrate that they will engage in
                substantial international trade between the United States and their
                country of citizenship. This makes the E-1 treaty trader visa a great
                entrepreneur visa USA option for those who do not have capital to invest
                but work in an industry that involves significant international trade with
                the United States.
              </p>
              <div className="accordion" id="internationalBusinessLaw">
                <div className="mb-5">
                  <div id="treatyTraderVisaZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#treatyTraderVisaTargetZero"
                      aria-expanded="true"
                      aria-controls="treatyTraderVisaTargetZero">
                      <strong>E-1 Treaty Trader Visa Requirements</strong>
                    </h2>
                  </div>
                  <div
                    id="treatyTraderVisaTargetZero"
                    className="collapse show"
                    aria-labelledby="treatyTraderVisaZero"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      To qualify for an E-1 treaty trader visa you must meet the following
                      requirements:
                    </p>
                    <ol className="corporateLawOrderedList">
                      <li className="noBold mb-4">
                        <strong>Treaty country nationality:</strong>
                        <p className="mt-4">
                          Similar to the E-2 investor visa USA, to be eligible for an E-1
                          visa you must be a citizen of a treaty country.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Engaged in substantial trade:</strong>
                        <p className="mt-4">
                          The E-1 treaty trader rules require that the applicant be
                          engaged in the substantial trade of goods or services. Similarly
                          to the E-2 investor visa USA, the term “substantial” is not
                          specifically defined in the E-1 visa context. US immigration
                          authorities consider both the monetary value and quantity of
                          transactions to determine whether you are engaged in substantial
                          trade. Generally, trade of over $100,000 for a period of at
                          least one year is considered substantial.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Trade occurs principally with treaty country:</strong>
                        <p className="mt-4">
                          The E-1 treaty visa rules require that 51% or more of the total
                          trade of the US company occur with the treaty country. You need
                          to be prepared to produce extensive documentation to establish
                          that your business principally trades with the treaty country.
                        </p>
                      </li>
                      <li className="noBold mb-4">
                        <strong>Activities must constitute trade:</strong>
                        <p className="mt-4">
                          The E-1 visa rules define trade as the international exchange of
                          qualifying goods or services. The rules broadly define what
                          goods or services qualify. Manufactured products, equipment, and
                          commodities have all been considered qualifying goods.
                          Insurance, financial, banking, and transportation services have
                          also qualified for an E-1 visa.
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="treatyTraderVisaOne">
                    <h2
                      className="text-title text-primary accordion-trigger collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#treatyTraderVisaTargetOne"
                      aria-expanded="false"
                      aria-controls="treatyTraderVisaTargetOne">
                      <strong>E-1 Treaty Trader Visa Advantages</strong>
                    </h2>
                  </div>
                  <div
                    id="treatyTraderVisaTargetOne"
                    className="collapse"
                    aria-labelledby="treatyTraderVisaOne"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body my-4">
                      The E-1 visa is regularly recommended as an alternative to other
                      investor visa USA programs because it does not formally require an
                      investment from the applicant. This makes it a tremendous
                      entrepreneur visa USA option for those companies already engaging in
                      trade between their home country and the United States. The E-1 visa
                      may also be a great investor immigration option for you if you see
                      an opportunity to start a low-cost import-export or an international
                      service business with the United States.
                    </p>
                    <p className="mb-4">
                      Similarly to the E-2 investor visa USA program, the E-1 treaty
                      trader visa provides the flexibility to renew your visa indefinitely
                      while also allowing you to depart the United States at any point.
                      This makes the E-1 treaty trader visa one of the best alternative
                      investment visa USA solutions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <h2 className="text-headline mb-5 mb-md-6">
                EB-5 Investor Visa Regional Center Project and Developer Representation
              </h2>
              <p className="text-body mb-6">
                I combine my immigration and business law experience to advise
                organizations in obtaining USCIS EB-5 investor visa Regional Center
                designations. Once a project is approved as a Regional Center, it may
                accept investments under the EB-5 investor visa USA program. Many foreign
                investors find Regional Centers to be an attractive option for their EB-5
                investor visa investment because the Regional Centers can count both
                direct and indirect jobs towards the mandatory job creation requirement.
                <br />
                <br />I conduct extensive planning and due diligence, working together
                with various professionals ranging from economists to securities lawyers,
                to help your organization comply with all USCIS regulations. I help your
                organization prepare and file Form I-924, Application for Regional Center
                Designation Under the Immigrant Investor Program, and guide you through
                the various nuanced requirements of the EB-5 investor visa USA program.
              </p>
            </div>
            <div className="col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Second Citizenship By Investment
              </h2>
              <p className="text-body mb-6">
                In addition to my investment immigration USA services, I also work with
                foreign legal counsel and global mobility professionals to help you obtain
                a second citizenship or residence in many international countries through
                investment. Many countries worldwide have established citizenship and
                residence by investment programs, similar to the EB-5 investor visa USA
                program.
                <br />
                <br />
                In Europe, countries like Cyprus and Malta offer citizenship for
                qualifying investors, while Greece, Portugal, and Bulgaria provide
                valuable European Union (EU) residence if you meet specific investment
                criteria. Several Caribbean nations, including Grenada, St. Lucia, and St.
                Kitts and Nevis also provide citizenship by investment programs. Grenada’s
                citizenship by investment is an interesting option for individuals from
                non-treaty countries who wish to move to the United States through the E-2
                entrepreneur visa USA program. Because Grenada is a treaty country, once
                you obtain Grenadian citizenship you may apply for the E-2 investor visa
                USA program.
                <br />
                <br />
                Are you a US citizen considering obtaining a second citizenship through
                investment? Although the US does not formally recognize dual citizenship,
                it also does not make you forfeit your US citizenship if you take up
                residence or citizenship in another country. Numerous US citizens hold
                dual citizenship.
                <br />
                <br />
                As part of my citizenship through investment services, I help you analyze
                the various citizenship and residence investor immigration programs based
                on your short and long-term goals, and assist you in determining the best
                program for your and your family’s specific needs. I use my extensive
                global contacts to help make your second citizenship or residence dream a
                reality.
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      {get(category, 'posts.nodes.length', 0) > 0 && (
        <>
          <hr className="my-8" />
          <CategorySlider index={2} category={category} />
        </>
      )}
      <AbTestingDeck
        title="Investor Immigration Client Reviews"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default InvestorVisaUsaImmigration;

export const query = graphql`
  query InvestorImmigrationQuery {
    category: wpCategory(slug: { eq: "investor-immigration" }) {
      ...BlogLandingHeroPost
    }
  }
`;
